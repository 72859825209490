import React, {useState, useEffect} from 'react';
import { BrowserRouter } from "react-router-dom";
import Navbar from './components/Navbar';
import AppRouter from './components/AppRouter';
import {AuthContext} from './context';

const stylesContainer = {
  margin: '0',
  padding: '0',
}
function App() {
    const [isAuth, setIsAuth] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (localStorage.getItem('auth')) {
            setIsAuth(true)
        }
        setIsLoading(false)
    }, [])

  return (
      <AuthContext.Provider value={{
          isAuth,
          setIsAuth,
          isLoading
      }}>
          <BrowserRouter>
              <Navbar />
              <div className="container mx-auto" style={stylesContainer}>
                  <AppRouter/>
              </div>
          </BrowserRouter>
      </AuthContext.Provider>
  );
}

export default App;
