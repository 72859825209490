import React, { useRef, useState, useEffect } from 'react';
import {openai} from '../API/BotService';
import '../scss/BotGptPage.scss';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import 'github-markdown-css';
import { FaCopy, FaCheck } from 'react-icons/fa';

export default function BotGptPage() {
    const [textareaValue, setTextareaValue] = useState('');
    const [messageHistory, setMessageHistory] = useState([]);
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);

    const containerRef = useRef(null);

    useEffect(() => {
        const containerEl = containerRef.current;
        containerEl.scrollTop = containerEl.scrollHeight;
    }, [messageHistory]);

    const handleCopyClick = (codeContent) => {
        navigator.clipboard.writeText(codeContent)
            .then(() => {
                console.log('Code copied to clipboard');
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            })
            .catch((error) => {
                console.error('Failed to copy code to clipboard:', error);
                // Handle the error state if desired
            });
    };

    const handleTextareaKeyDown = (e) => {
        if (e.shiftKey && e.key === 'Enter') {
            e.preventDefault();  // prevent default behavior (new line)
            sendQuery()
                .catch((e) => {
                    console.log(e)
                });
        }
    };

    const resetForm = () => {
        setTextareaValue('')
        setMessageHistory([])
    }

    const sendQuery = async () => {
        setLoading(true);
        try {
            const messages = messageHistory.map((message) => ({
                role: 'system',
                content: message.content,
            }));

            const response = await openai.chat([...messages, {
                role: openai.roles.USER,
                content: textareaValue,
            }])

            const newMessage = {
                role: openai.roles.ASSISTANT,
                query: textareaValue,
                content: response.content,
            }

            setMessageHistory([...messageHistory, newMessage]);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
        setTextareaValue('');
    };

    const renderCodeBlock = (code) => {
        const regex = /```([^\n]+)?\n([\s\S]+?)\n```/g;
        let replacedText = code || "";
        const placeholders = [];

        replacedText = replacedText.replace(regex, (match, language, codeContent) => {
            const placeholder = `__CODE_BLOCK_${placeholders.length}__`;
            placeholders.push({language: language || '', codeContent: codeContent.trim()});
            return placeholder;
        });

        const replacedElements = replacedText.split('__CODE_BLOCK_').map((element, index) => {
            if (index === 0) {
                const result = element.split('\n').map((line, index) => (
                    <div key={index}>{line}</div>
                ))
                return result;
            }
            const placeholderIndex = element.indexOf('__');
            const key = element.substring(0, placeholderIndex);
            const { language, codeContent } = placeholders[parseInt(key, 10)];
            return (
                <div key={key} className="code-wrapper">
                    <div className="code-header">
                        <span className="language">{language}</span>
                        {copied ? (
                            <div className="copy-success">
                                <FaCheck className="check-icon" />
                                Code Copied!
                            </div>
                        ) : (
                            <button
                                className="copy-button"
                                onClick={() => handleCopyClick(codeContent)}
                            >
                                <FaCopy />
                            </button>
                        )}
                    </div>
                    <SyntaxHighlighter language={language} style={vscDarkPlus} className="code-block">
                        {codeContent}
                    </SyntaxHighlighter>
                </div>
            );
        });

        return <div>{replacedElements}</div>;
    };

    return (
        <div className="col-9 mx-auto">
            <div className="query-wrapper">
                <div className="mb-3">
                    <textarea
                        className="form-control form-control-sm query-textarea"
                        id="exampleFormControlTextarea1"
                        rows="4"
                        value={textareaValue}
                        onChange={(e) => setTextareaValue(e.target.value)}
                        onKeyDown={handleTextareaKeyDown}
                    ></textarea>
                </div>
                <div className="col-12 d-flex justify-content-between">
                    <button className="btn btn-success" type="button" onClick={sendQuery} disabled={loading}>
                        {loading ? 'Sending...' : 'Send'}
                    </button>
                    <button className="btn btn-danger" type="button" onClick={resetForm}>
                        Reset session
                    </button>
                </div>
            </div>
            <div ref={containerRef} className="response-wrapper">
                {messageHistory.map((message, index) => (
                    <div key={index}>
                        <div className="query-text">Query: {message.query.split('\n').map((line, index) => (
                            <div key={index}>{line}</div>
                        ))}</div>
                        <hr/>
                        <div>Response: </div>
                        <div className="api-response-container">
                            {renderCodeBlock(message.content)}
                        </div>
                        <hr/>
                        <hr/>
                    </div>
                ))}
            </div>
        </div>
    )
}
