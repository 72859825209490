import React, {useContext} from 'react';
import '../scss/Login.scss'
import MyInput from '../components/UI/Input/MyInput';
import MyButton from '../components/UI/button/MyButton';
import {AuthContext} from '../context';

const Login = () => {
    const {setIsAuth} = useContext(AuthContext)
    const login = event => {
        event.preventDefault()
        setIsAuth(true)
        localStorage.setItem('auth', true)
    }

    return (
        <div className="login-container">
            <div className="login-block">
                <h1>Login</h1>
                <form style={{width: 300}} onSubmit={login}>
                    <MyInput type="email" placeholder="Enter email"/>
                    <MyInput type="password" placeholder="Enter password"/>
                    <MyButton style={{marginLeft: 'auto'}}>Login</MyButton>
                </form>
            </div>
        </div>
    );
};

export default Login;
