import React from 'react'
import '../scss/AboutPage.scss'

export default function AboutPage() {
  return (
      <div className="cv-page-wrapper">
          <h1>About Page</h1>
          <div className="cv-block cv-main-info">
              {/*<p className="cv-main-info-item">Minsk, Belarus</p>*/}
              <p className="cv-main-info-item"><a href="mailto:eugen.kopyl@gmail.com">eugen.kopyl@gmail.com</a></p>
              {/*<p className="cv-main-info-item">35 years old</p>*/}
              {/*<p className="cv-main-info-item">Code wars: <a href="https://www.codewars.com/users/Blade_tm" target="_blank" rel="noreferrer">Blade_tm</a></p>*/}
              {/*<p className="cv-main-info-item">Married</p>*/}
              {/*<p className="cv-main-info-item">web: <a href="https://smcx.ru/" target="_blank" rel="noreferrer">www.smcx.ru</a></p>*/}
              {/*<p className="cv-main-info-item"><a href="tel:+375-29-6043876">+375-29-6043876</a></p>*/}
              <p className="cv-main-info-item">Git: <a href="https://github.com/EugeneKopyl" target="_blank" rel="noreferrer">github.com/EugeneKopyl</a></p>
              <p className="cv-main-info-item">LinkedIn: <a href="https://www.linkedin.com/in/eugene-kopyl-59baa5b4/" target="_blank" rel="noreferrer">LinkedIn</a></p>
          </div>
          {/*<div className="cv-block cv-summary">*/}
          {/*  <h2>Summary</h2>*/}
          {/*  <p>JavaScript React developer. Experience in web-development about 2 year. English level – Intermediate</p>*/}
          {/*</div>*/}
          {/*<div className="cv-block cv-skills">*/}
          {/*  <h2>Skills with experience</h2>*/}
          {/*  <ul className="cv-skills-list">*/}
          {/*    <li>JavaScript</li>*/}
          {/*    <li>Html</li>*/}
          {/*    <li>css</li>*/}
          {/*    <li>React</li>*/}
          {/*    <li>TypeScript</li>*/}
          {/*    <li>Webpack</li>*/}
          {/*    <li>NodeJs</li>*/}
          {/*    <li>Git</li>*/}
          {/*    <li>Figma</li>*/}
          {/*    <li>Ajax</li>*/}
          {/*    <li>Bootstrap</li>*/}
          {/*    <li>Sass</li>*/}
          {/*    <li>Photoshop</li>*/}
          {/*    <li>JQuery</li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
          {/*<div className="cv-block cv-employment">*/}
          {/*  <h2>Employment</h2>*/}
          {/*  <p>*/}
          {/*    VRP Consulting*/}
          {/*    Front-end Developer*/}
          {/*    04/2021 – present.*/}
          {/*    Project (salesforce.by)s*/}
          {/*  </p>*/}
          {/*  <p>*/}
          {/*    Freelance*/}
          {/*    Front-end Developer*/}
          {/*    09/2019 – present*/}
          {/*    Freelance work. Development my own site www.smcx.ru*/}
          {/*  </p>*/}
          {/*</div>*/}
          {/*<div className="cv-block cv-education">*/}
          {/*  <h2>Education and courses</h2>*/}
          {/*  <p>Vladilen Minin 2020, JavaScript professional</p>*/}
          {/*  <p>Htmlacademy.ru 2019 HTML, CSS, JavaScript</p>*/}
          {/*  <p>Belarusian State University of Informatics and Radioelectronics 2006-2010 Electronics Engineer</p>*/}
          {/*</div>*/}
          {/*<div className="cv-block cv-language">*/}
          {/*  <h2>Language</h2>*/}
          {/*  <p>English - Intermediate (B1)</p>*/}
          {/*  <p>Russian - Native</p>*/}
          {/*</div>*/}
      </div>
  )
}
