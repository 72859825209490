import React, {useContext} from "react";
import { NavLink } from "react-router-dom";
import '../scss/Navbar.scss'
import {AuthContext} from '../context';

export default function Navbar() {
  const {isAuth, setIsAuth} = useContext(AuthContext)

  const logout = () => {
    setIsAuth(false)
    localStorage.removeItem('auth')
  }

  const toggleClassName = (e) => {
    e.currentTarget.classList.toggle('is-active')
  }

  const closeMobileMenu = () => {
    let menu = document.querySelector('.hamburger')
    menu.classList.remove('is-active')
  }

  return (
      <header className="page__header">
        <div className="page__header-logo">
          <NavLink to="/"></NavLink>
        </div>
        <nav className="main-nav" role="navigation">
          <button className="hamburger hamburger--squeeze" type="button" onClick={toggleClassName}>
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
          <ul className="nav-links" onClick={closeMobileMenu}>
            <li className="first-link">
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/posts">Posts</NavLink>
            </li>
            {/*<li>*/}
            {/*  <NavLink to="/gallery">Gallery</NavLink>*/}
            {/*</li>*/}
            <li>
              <NavLink to="/botgpt">Bot GPT</NavLink>
            </li>
            <li>
              <NavLink to="/about">about</NavLink>
            </li>
            {isAuth && <li onClick={logout}>
              <a href="/">exit</a>
            </li>
            }
          </ul>
        </nav>
      </header>
  );
};
