import Sun from './0_Sun.gif'
import Mercury from './1_Mercury.gif'
import Venus from './2_Venus.gif'
import Earth from './3_Earth.gif'
import Mars from './4_Mars.gif'
import Jupiter from './5_Jupiter.gif'
import Saturn from './6_Saturn.gif'
import Uran from './7_Uran.gif'
import Neptun from './8_Neptun.gif'

export const planets = [
  Sun,
  Mercury,
  Venus,
  Earth,
  Mars,
  Jupiter,
  Saturn,
  Uran,
  Neptun,
]
