import {useEffect, useRef} from 'react';

// export const useObserver = (ref, canLoad, isLoading, callback) => {
//     const observer = useRef()
//
//     useEffect(() => {
//         if (isLoading) return;
//         if (observer.current) observer.current.disconnect()
//
//         let cb = function (entries, observer) {
//             if (entries[0].isIntersecting && canLoad) {
//                 callback()
//             }
//         }
//         observer.current = new IntersectionObserver(cb)
//         observer.current.observe(ref.current)
//     }, [isLoading])
//
// }

export const useObserver = (ref, canLoad, isLoading, callback) => {
    const observer = useRef();

    useEffect(() => {
        if (isLoading) return;

        const cb = function (entries, observer) {
            if (entries[0].isIntersecting && canLoad) {
                callback();
            }
        };

        // disconnect the previous observer
        if (observer.current) observer.current.disconnect();

        // create a new observer and observe the ref
        observer.current = new IntersectionObserver(cb);
        if (ref.current) {
            observer.current.observe(ref.current);
        }

        // disconnect the observer when the component unmounts
        return () => observer.current.disconnect();
    }, [ref, canLoad, isLoading, callback]);
};
