import AboutPage from '../pages/AboutPage';
import GalleryPage from '../pages/GalleryPage';
import PostsPage from '../pages/PostsPage';
import BotGptPage from '../pages/BotGptPage';
import HomePage from '../pages/HomePage';
import PostIdPage from '../pages/PostIdPage';
import Login from '../pages/Login';

export const privateRoutes = [
    {path: '/gallery', element: <GalleryPage/>, exact: false},
    {path: '/posts', element: <PostsPage/>, exact: true},
    {path: '/botgpt', element: <BotGptPage/>, exact: false},
    {path: '/posts/:id', element: <PostIdPage/>, exact: true},
    {path: '/about', element: <AboutPage/>, exact: false},
    {path: '/', element: <HomePage/>, exact: true},
]
export const publicRoutes = [
    {path: '/about', element: <AboutPage/>, exact: false},
    {path: '/', element: <HomePage/>, exact: true},
    {path: '/login', element: <Login/>, exact: false},
]
