import { Configuration, OpenAIApi } from 'openai'

const text = 'sk-qepUX44xXu'+'9WVqSWqraC'+'T3BlbkFJPWOD'+'TRTbz7OBsyNOy9uq';

class BotService {
    roles = {
        ASSISTANT: 'assistant',
        USER: 'user',
        SYSTEM: 'system',
    }
    constructor(apiKey) {
        const configuration = new Configuration({
            apiKey,
        })
        console.log(apiKey)
        this.openai = new OpenAIApi(configuration)
    }

    async chat(messages) {
        try {
            const response = await this.openai.createChatCompletion({
                model: 'gpt-3.5-turbo',
                messages,
            })
            return response.data.choices[0].message
        } catch (e) {
            console.log('Error while gpt chat', e.message)
        }
    }
}

export const openai = new BotService(text)
