import React, {useEffect, useRef, useState} from 'react'
import '../scss/PostsPage.scss'
import PostList from '../components/PostList';
import PostForm from '../components/PostForm';
import {usePosts} from '../hooks/usePosts';
import PostFilter from '../components/PostFilter';
import MyModal from '../components/UI/MyModal/MyModal';
import MyButton from '../components/UI/button/MyButton';
import Loader from '../components/UI/Loader/Loader';
import PostService from '../API/PostService';
import {useFetching} from '../hooks/useFetching';
import {getPagesCount} from '../utils/pages';
import Pagination from '../components/UI/pagination/Pagination';
import {useObserver} from '../hooks/useObserver';
import MySelect from '../components/UI/Select/MySelect';

export default function PostsPage() {

    const [posts, setPosts] = useState([])
    const [filter, setFilter] = useState({sort: '', query: ''})
    const [modal, setModal] = useState(false)
    const [totalPages, setTotalPages] = useState(8)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(0)
    const sortedAndSearchedPosts = usePosts(posts, filter.sort, filter.query)
    const lastElement = useRef()

    const [fetchPosts, isPostLoading, postError] = useFetching(async (limit, page) => {
        const res = await PostService.getAll(limit, page)
        setPosts([...posts, ...res.data])
        const totalCount = res.headers['x-total-count']
        setTotalPages(getPagesCount(totalCount, limit))
    })

    useObserver(lastElement, page < totalPages, isPostLoading, () => {
        setPage(page + 1)
    })

    useEffect(() => {
        fetchPosts(limit, page)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit])

    const createPost = (newPost) => {
        setPosts([...posts, newPost])
        setModal(false)
    }

    const removePost = (post) => {
        setPosts(posts.filter(p => p.id !==post.id))
    }

    const changePage = (page) => {
        setPage(page)
    }

    return (
        <div className='App'>
            <MyButton style={{marginTop: 30}} onClick={() => setModal(true)}>
                Create post
            </MyButton>
            <MyModal visible={modal} setVisible={setModal}>
                <PostForm create={createPost}/>
            </MyModal>
            <PostFilter filter={filter} setFilter={setFilter}/>
            <MySelect value={limit} onChange={value => setLimit(value)} defaultValue='elements on page' options={[
                {value: 5, name: '5'},
                {value: 10, name: '10'},
                {value: 25, name: '25'},
                {value: -1, name: 'Show all'},
            ]}/>
            {postError &&
                <h1>Error: {postError}</h1>
            }
            <PostList remove={removePost} posts={sortedAndSearchedPosts} title="Posts list"/>
            <div ref={lastElement} style={{height: 20, background: 'transparent'}}></div>
            {isPostLoading &&
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 50}}><Loader/></div>
            }
            <Pagination page={page} changePage={changePage} totalPages={totalPages}/>
        </div>
    )
}
