import React, { useState } from 'react'
import '../scss/GalleryPage.scss'
import {planets as Images} from '../resources/Planets/images'

export default function GalleryPage() {
  const [selImg, setSelImg] = useState(Images[0])

  return (
    <div className="gallery-page">
      <h1>Gallery</h1>
      <div className="gallery">
        {Images.map((img, index) => (
          <div key={index} className={selImg === img ? 'gallery-item active-image' : 'gallery-item'}>
            <img src={img} alt="Planet" className="gallery-image" onClick={() => setSelImg(img)}/>
          </div>
        ))}
      </div>
      <div className="gallery-selected-item">
        <img src={selImg} alt="Planet" className="gallery-image"/>
      </div>
    </div>
  )
}

